* {
    font-family: "Roboto", sans-serif;
    margin: 0;
}

body {
    margin: 0;
    text-decoration: none !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    text-align: right;
}
